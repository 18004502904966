<template>
  <div class="auth-wrapper auth-v2 px-2">
    <showAt breakpoint="small">
        <div class="btn_prev_container">
            <div class="btn_prev_stage_mobile" style="margin-top: 20px;"> 
                <b-link @click="goBack">
                    <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
                </b-link>
            </div>
        </div>
    </showAt>
    <div class="auth-inner py-2">

      <!-- Register v2 -->
      <b-card class="mb-0">
        
        <center>
            <showAt breakpoint="mediumAndAbove">

            <div class="btn_prev_stage_desktop" style="margin-top: 20px;"> 
                <b-link @click="goBack">
                    <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
                </b-link>
            </div>
            </showAt>
            <b-img v-if="skin.value === 'light' " src="~@/assets/images/logo/logo_title_flat.png" fluid width="110px" />
            <b-img v-else src="~@/assets/images/logo/logo_title_flat_white.png" fluid width="110px" />
        </center>
          
        <b-card-title class="text-center" style="margin-top: 32px; margin-bottom: 4px; font-size: 20px">
          {{$t('register.title')}}
        </b-card-title>
        <b-card-text class="mb-2 text-center text-muted" style="font-size: 14px;">
          {{$t('register.subtitle-a')}}
            <b-img
                src="~@/assets/images/icons/emoji/rocket.png"
                fluid
                alt="rocket"
                style="width: 18px; height: 18px; margin-right: 3px; margin-left: 2px"
            ></b-img> <br>
          {{$t('register.subtitle-b')}}
        </b-card-text>
        <b-card-text class="mb-2 text-center" style="font-size: 14px;">
            {{$t('register.step')}} {{stage}} <span class="text-muted">{{$t('register.of')}} 4</span>
        </b-card-text>

      <validation-observer 
        ref="registerForm"
        #default="{invalid}"
      >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- Stage-1 -->
            <!-- username -->
            <b-form-group
              v-if="stage == 1"
            >
              <validation-provider
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                 :class="!validForm ? 'is-invalid':null"
                  name="register-username"
                  :placeholder="$t('register.placeholders.name')"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>

            <!-- mask --> 
            <b-form-group
              v-if="typeof country.mask == 'object' && stage == 1"
            >
              <v-select
                v-if="typeof country.mask == 'object'"
                :options="country.mask"
                v-model="selected_mask"
                label="mask"
                :menu-props="{closeOnClick: false}"
                :clearable="false"
              />
            </b-form-group>

            <!-- phone number -->
            <b-form-group
              v-show="!isCodeSend"
              v-if="stage == 1"
            >
              <validation-provider
                name="Phone"
                rules="required"
              >
                  <b-input-group class="input-group-merge" :class="!validForm ? 'is-invalid':null">
                        <b-input-group-prepend is-text>
                            <b-link v-if="country" @click="showCountryModal = true" class="text-black" style="font-size: 13px; display: flex; align-items: center;">
                                <!-- <b-img :src="country.flag" fluid style="width: 15px; height: 15px; margin-right: 6px;" /> -->
                                <country-flag :country="country.iso" size='small'/>
                                <span>{{ country.code }}</span>
                            </b-link>
                        </b-input-group-prepend>    
                        <b-form-input
                          id="phone"
                          v-model="regPhone"
                          :state="!validForm ? false:null"
                          name="register-phone"
                          :placeholder="country ? (typeof country.mask == 'object' ? selected_mask : country.mask) : '### ### ##-##'"
                          @focus="applyMask"
                          v-mask=" country ? (typeof country.mask == 'object' ? selected_mask : country.mask) : '### ### ##-##'"
                          class="form-control-merge"
                        /> 
                  </b-input-group>  
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>
            <b-card-text v-if="stage == 1" class="mb-2 text-center" style="font-size: 14px;">
                {{$t('register.send-sms-msg')}}
            </b-card-text>
            <!-- submit button -->
            <b-button v-if="stage == 1"
              variant="primary"
              block
              type="submit"
              class="submit-auth-v2"
              :disabled="invalid"
            >
              {{$t('register.btns.send-sms')}}
            </b-button>

            <!-- Stage-2 -->
            <!-- code -->
            <b-form-group 
              :label="$t('register.labels.sms-code')"
              v-if="stage == 2"
              class="stage-2"
            >
              <validation-provider
              name="regCode"
              rules="required"
              >
              <b-form-input
                id="code"
                v-model="regCode"
                :class="!validForm ? 'is-invalid':null"
                name="register-code"
                placeholder="000000"
                v-mask="'######'"
              />
              <b-input-group-append>
                <span id="timer"></span>
              </b-input-group-append>
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>

            <b-card-text v-if="stage === 2" class="mb-2 text-center" style="font-size: 14px;">
              {{$t('register.didnt-send-code')}}
              <b-link @click="requestPhoneConfirmation(true)">
              {{$t('register.send-code-again')}}
              </b-link>
            </b-card-text>

            <!-- submit button -->
            <b-button
              v-if="stage == 2"
              variant="primary"
              block
              type="submit"
              class="submit-auth-v2"
              :disabled="invalid"
            >
              {{$t('register.btns.next')}}
            </b-button>

            <!-- Stage-3 -->
            <!-- password -->
            <b-form-group
            v-if="stage == 3"
            >
              <validation-provider
                name="Password"
                vid="password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="!validForm ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="userPassword"
                    :type="passwordFieldType"
                    :state="!validForm > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    :placeholder="$t('register.placeholders.password')"
                    @focus="focusPassword = true"
                    @blur="focusPassword = false"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <transition name="fade">
                    <div v-if="focusPassword" class="d-flex validation-card mt-1" style="flex-direction:column">
                        <span class="font-weight-bolder">{{$t('register.validate.msg')}}</span>
                        <span :class="validationPassword.minLength ? 'text-success' : 'text-danger'">  
                            <feather-icon v-if="validationPassword.minLength" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                              {{$t('register.validate.symbols')}}
                        </span>
                        <span  :class="validationPassword.register ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationPassword.register" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                              {{$t('register.validate.upper-lower-case')}}
                        </span>
                        <span  :class="validationPassword.numbers ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationPassword.numbers" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.numbers')}}
                        </span>
                        <span  :class="validationPassword.specialСharacter ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationPassword.specialСharacter" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.special-sharacter')}} <br>
                            ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _` { | } ~ 
                        </span>
                    </div>
                </transition>
              </validation-provider>
            </b-form-group>
            
            <b-form-group
            v-if="stage == 3"
            >
              <validation-provider
                name="Password"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="!validForm ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password_confirm"
                    v-model="confirm_password"
                    :type="passwordFieldType"
                    :state="!validForm > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password-confirm"
                    :placeholder="$t('register.placeholders.confirm-password')"
                    @focus="focusConfirmPassword = true"
                    @blur="focusConfirmPassword = false"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <transition name="fade">
                    <div v-if="focusConfirmPassword" class="d-flex validation-card mt-1" style="flex-direction:column">
                        <span class="font-weight-bolder">Пароль должен содержать</span>
                        <span :class="validationConfirmPassword.minLength ? 'text-success' : 'text-danger'">  
                            <feather-icon v-if="validationConfirmPassword.minLength" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.symbols')}}
                        </span>
                        <span  :class="validationConfirmPassword.register ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationConfirmPassword.register" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.upper-lower-case')}}
                        </span>
                        <span  :class="validationConfirmPassword.numbers ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationConfirmPassword.numbers" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.numbers')}}
                        </span>
                        <span  :class="validationConfirmPassword.specialСharacter ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationConfirmPassword.specialСharacter" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.special-sharacter')}} <br>
                            ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _` { | } ~ 
                        </span>
                    </div>
                </transition>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button
            v-if="stage == 3"
              variant="primary"
              block
              type="submit"
              class="submit-auth-v2"
              :disabled="invalid"
            >
              {{$t('register.btns.next')}}
            </b-button>


            <!-- Stage-4 -->
            <!-- email -->
            <b-form-group
              v-if="stage == 4"
            >
              <validation-provider
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="!validForm ? false:null"
                  name="register-email"
                  :placeholder="$t('register.placeholders.email')"
                />
              </validation-provider>
            </b-form-group>       
            
            <!-- checkbox -->
            <b-form-group
              v-if="stage == 4"
            >
              <validation-provider
                name="confirm"
                rules="required|checked"
              >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                  class="policy-auth"
                >
                    <span style="font-size: 14px; line-height: 12px" :class=" !validForm ? 'text-danger' : ''">
                        <span v-if="!validForm">
                          Для продолжение регистрации необходимо Ваше согласие c
                          {{$t('register.consert-privacy-a')}}
                        </span>
                        <span v-else >{{$t('register.agree-privacy')}}</span>
                        <b-link :to="{ name: 'page-policy' }">{{$t('register.policy')}}</b-link>
                        <span>{{$t('register.and')}}<b-link :to="{ name: 'page-public-offer' }">{{$t('register.offer')}}</b-link>
                        <span v-if="!validForm">
                          {{$t('register.consert-privacy-b')}}
                        </span>
                        .
                        </span>
                    </span>
                </b-form-checkbox>               
    
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button
              v-if="stage == 4"
              variant="primary"
              block
              type="submit"
              class="submit-auth-v2"
              :disabled="invalid"
            >
              {{$t('register.btns.complete')}}
            </b-button>

          </b-form>
        </validation-observer>

        <b-card-text v-if="error" variant="danger" class="text-danger text-center mt-2"> {{ error }}</b-card-text>
        
        <b-card-text class="text-center" style="margin-top: 32px">
          <span>{{$t('register.already-account')}}</span>
          <b-link :to="{name:'auth-login'}">
            <span>{{$t('register.btns.log-in')}}</span>
          </b-link>
        </b-card-text>

        
      </b-card>
      
    <!-- /Register v2 -->
    </div>
      
    <b-modal 
        v-model="showCountryModal"
        centered
        :title="$t('modals.country.title')"
        ok-only
        :ok-title="$t('modals.country.title')"
        modal-class="with-no-paddings select-countries"
    >

        <b-list-group flush style="max-height: 255px; overflow-y: scroll;">
            <b-list-group-item v-for="c in countries" :key="c.iso" @click="country = c; showCountryModal = false;" :active=" country ? (c.iso === country.iso) : false " style="cursor: pointer;">
                <div class="d-flex justify-content-start align-items-center">
                    <div style="margin-right: 8px;">
                        <!-- <b-img :src="c.flag" fluid /> -->
                        <country-flag :country="c.iso" size='small'/>
                    </div>
                    <div class="flex-grow-1">
                        {{ c.name }}
                    </div>
                    <div>
                        {{ c.code }}
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>
      
    </b-modal>  
      
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email, password, confirmed, checked } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import * as CryptoJS from "crypto-js"


import useAppConfig from '@core/app-config/useAppConfig'

import {
  isValidPhoneNumber
} from 'libphonenumber-js'

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,

  },
  mixins: [togglePasswordVisibility],
  metaInfo() {
    const title = this.$t('title-pages.register');
    return {
      title: title,
      titleTemplate: null,
    }
  },
  data() {
    return {
      stage: 1,
      counter: null,
	    isResendCode: false,
	    isCodeSend: false,
      isWaitResponse: false,

      selected_mask: null,
      country: {
        "name": "Russia",
        "code": "+7",
        "iso": "RU",
        "flag": "https://www.countryflags.io/RU/flat/24.png",
        "mask": "(###)###-##-##"
      },
      countries: require('countries-phone-masks'),
      showCountryModal: false,  
        
      username: '',
      regEmail: '',
      regPhone: '',
      regCode: '',
      userPassword: null,
      confirm_password: null,

      i: null,

      status: '',

      error: null,
      validForm: true,
      
      url_params: new URLSearchParams(window.location.search),
      
      skin: useAppConfig().skin,

      validationPassword: {
          minLength: false,
          register: false,
          numbers: false,
          specialСharacter: false
      },
      validationConfirmPassword: {
          minLength: false,
          register: false,
          numbers: false,
          specialСharacter: false
      },

      focusConfirmPassword: false,
      focusPassword: false,

      // validation rules
      required,
      confirmed,
      password,
      email,
      checked
    }
  },
  mounted() {
    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale.toUpperCase();

    console.log('ISO код страны');
    console.log(userLocale);

    this.country = this.countries.find( country => country.iso === userLocale );

    if (this.country == undefined) {
      this.country = this.countries.find( country => country.iso === 'RU' );
    }

    this.parseLocal();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  watch: {
    country: {
      deep: true,
      handler(bef) {
        if (typeof this.country.mask == 'object') {
          this.selected_mask = this.country.mask[0]
        }
      }
    },
    userPassword: {
      deep: true,
      handler(bef) {
        this.validationPassword.specialСharacter = this.checkSpecialСharacter(bef);
        this.validationPassword.minLength = bef.length >= 10 ? true : false;
        this.validationPassword.register = this.checkCasesStr(bef)
        this.validationPassword.numbers = /[0-9]/.test(bef);
      }
    },
    confirm_password: {
      deep: true,
      handler(bef) {
        this.validationConfirmPassword.specialСharacter = this.checkSpecialСharacter(bef);
        this.validationConfirmPassword.minLength = bef.length >= 10 ? true : false;
        this.validationConfirmPassword.register = this.checkCasesStr(bef)
        this.validationConfirmPassword.numbers = /[0-9]/.test(bef);
      }
    },
    regEmail: {
      deep: true,
      handler(bef) {
        this.saveLocal();
      }
    }
  },
  methods: {
    validationForm() {
        this.error = null;  
        // this.errors = [];
       
        this.$refs.registerForm.validate().then(success => {

        if(this.stage === 2 && this.isCodeSend === false ) {
          return this.$toast({
            component: ToastificationContent,
              props: {
                title: this.$t('toasts.titles.confirm'),
                text: this.$t('toasts.text.confirm-phone'),
                variant: 'danger',
              },
            });
        }
        
        this.validForm = success
                    
        if (success) {

          if (this.stage === 1) {
              this.requestPhoneConfirmation();
              return
          }

          if (this.stage === 2) {
              this.checkSmsCode()
              return
          }

          if (this.stage === 3) {
              this.nextStage()
              return
          }

          if (this.stage === 4) {

            if (this.isWaitResponse) return;

            this.isWaitResponse = true;
            this.$request.post("user.register", {
                name: this.username,
                email: this.regEmail,
                password: this.userPassword,
                referral: this.url_params.get('ref') ? this.url_params.get('ref') : null,
                utm_tags: localStorage.getItem("utmTags") ? JSON.parse(localStorage.getItem("utmTags")) : null,
                i: this.i,
                c: this.regCode,
            }).then( result => {
                
                this.isWaitResponse = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.register'),
                      title: this.$t('toasts.text.register'),
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  });
                localStorage.removeItem('register:form');
                this.$router.replace("/authorization");
                
            }).catch(err => {
              this.isWaitResponse = false;
              this.error = err.message; 
            });
          }
            

        } else {
          if (this.stage === 3) {
            if (this.userPassword !== this.confirm_password) this.error = 'Пароли должны совпадать'
            return
          }
        }
      })
    },

    goBack() {
      if (this.stage !== 1) {
          this.stage = this.stage - 1;
          if (this.counter) clearInterval(this.counter);
          if (this.stage === 1) { 
            this.isCodeSend = false;
            this.isResendCode = false;
          }
      } else this.$router.push('/')
    },

    countdown() {
      const t = this;

      t.isResendCode = true;

      let now = new Date();
      let timeup = now.setSeconds(now.getSeconds() + 30);

      if (this.counter) clearInterval(this.counter);
      this.counter = setInterval(timer, 1000);

      function timer() {
      now = new Date();
      let count = Math.round((timeup - now) / 1000);
      if (now > timeup) {
        clearInterval(t.counter);
        document.getElementById("timer").innerHTML = "00:00";
        t.isResendCode = false;
        return;
      }
      let seconds = Math.floor(count % 60);
      document.getElementById("timer").innerHTML =
        "00" + ":" + (seconds < 10 ? "0" + seconds : seconds);
      }
    },

    showCountries() {
      this.showCountryModal = true
    },

    nextStage() {
      this.stage = this.stage + 1;
      this.saveLocal();
    },
      
    isPhoneNumberValid( phone ) {
      
        if( this.country ) {
          return isValidPhoneNumber( `${this.country.code}${phone}`, "INTERNATIONAL" );        
        } else {
          return isValidPhoneNumber( `${phone}`, "INTERNATIONAL" );       
        }

    },  
    
    canclePhoneConfirmation( args ) {
        this.isCodeSend = false;
    },
    
    requestPhoneConfirmation( resend = false ) {

        if (this.isResendCode) return;
		    this.countdown();

        this.$request.post("user.phoneConfirm", {
            phone: this.country ? `${this.country.code}${this.regPhone}` : this.regPhone,
            locale: this.$i18n.locale
        }).then( rsp => { 
            this.i = rsp.i;
            this.isCodeSend = true;
            if (!resend) this.nextStage()
        });
    },
      
    applyMask( args ) {
        args.target.setAttribute("v-mask", '##########');
    },  

    checkSpecialСharacter(str) {
        let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return format.test(str);
    },

    checkCasesStr(str) {

        let upper = false;
        let lower = false;

        let i=0;
        let character='';
        while (i <= str.length) {
            character = str.charAt(i);
            if (!isNaN(character * 1)){
            }else if (!this.checkSpecialСharacter(character)){
                
                if (character == character.toUpperCase()) {
                   upper = true
                }
                if (character == character.toLowerCase()){
                   lower = true
                }
            }
            i++;
        }
        if (str.search(/[А-яЁё]/) !== -1) return false

        if (upper && lower) return true;
        else return false;
    },

    checkSmsCode() {
        this.$request.post("user.checkSmsCode", {
            i: this.i,
            c: this.regCode,
        }).then( rsp => { 
            clearInterval(this.counter);
            this.validForm = true;
            this.nextStage();
        }).catch(error => {
            this.validForm = false;
            this.$toast({
            component: ToastificationContent,
              props: {
                title: this.$t('toasts.titles.invalid-code'),
                variant: 'danger',
              },
            });
        });
    },

    saveLocal() {
      localStorage.setItem('register:form', JSON.stringify({
        stage: this.stage,
        country: this.country,
        regEmail: this.regEmail,
        regPhone: this.regPhone,
        regCode: this.regCode,
        username: this.username,
        userPassword: this.userPassword ? CryptoJS.AES.encrypt( this.userPassword , process.env.VUE_APP_PK ).toString() : null,
        confirm_password: this.confirm_password ? CryptoJS.AES.encrypt( this.confirm_password , process.env.VUE_APP_PK ).toString() : null,
        i: this.i

      }) );
    },

    parseLocal() {
      if (localStorage.getItem('register:form')) {
          let form = JSON.parse( localStorage.getItem('register:form'));

          this.stage = form.stage;
          this.country = form.country,
          this.regEmail = form.regEmail;
          this.regPhone = form.regPhone;
          this.regCode = form.regCode;
          this.username = form.username;
          this.userPassword = form.userPassword ? CryptoJS.AES.decrypt( form.userPassword , process.env.VUE_APP_PK ).toString(CryptoJS.enc.Utf8) : null;
          this.confirm_password = form.confirm_password ? CryptoJS.AES.decrypt( form.confirm_password , process.env.VUE_APP_PK ).toString(CryptoJS.enc.Utf8) : null;
          this.i = form.i;
      }
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
